
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Field } from "vee-validate";
import store from "@/store";

export default defineComponent({
  name: "motor-insurance-existing-policy-quote",
  components: {
    Field
  },
  data() {
    return {};
  },
  setup() {
    const user = computed(() => {
      return store.getters.currentUser;
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Choice Selection", [
        "Insurance Packages",
        "Motor Insurance"
      ]);
    });

    return { user };
  },
  created() {
    //Set page title
    document.title =
      "Choice Selection: Motor Insurance Quote | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    buyNewPolicy() {
      this.$router.push({ name: "motor-insurance-package-quote" });
    },

    addToPolicy() {
      this.$router.push({ name: "client-policy-information-motor-addition" });
    },

    renewPolicy() {
      this.$router.push({ name: "client-policy-information-motor-renewal" });
    }
  }
});
